import React, { useState, useEffect } from 'react';
import './Login.css';
import RightBanner from './RightBanner';
import { Link } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import loading from "../../assets/images/loader_grey.gif";
import { forgotPassword } from '../../services/user';


function ForgotPassword() {

    const [email, setEmail] = useState("");
    const [errEmail, setErrEmail] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [successMsg, setSuccessMsg] = useState();
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState();


    var emailInp;

    useEffect(() => {
        emailInp = document.getElementById("email-input");
    });

    const validateFields = () => {
        if (email == "") {
            setErrEmail(true);
            emailInp.classList.add("error-input-border");
            return false;
        }
        return true;
    }

    const onChangeEmail = (val) => {
        if (errEmail == true) {
            emailInp.classList.remove("error-input-border");
            setErrEmail(false);
        }
        setEmail(val);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
            try {
                setAlertMsg(true);
                const response = await forgotPassword({
                    email
                });
                if (response.status) {
                    setAlertMsg(false);
                    setEmail("");
                    setAlertError(false);
                    setAlertSuccess(true);
                    setSuccessMsg("Reset Password Link has been sent to your email");
                } else {
                    setErrMsg(response.message);
                    setAlertError(true);
                    setAlertMsg(false);
                }
            } catch (error) {
                // console.error(error);
                setErrMsg("Internal Server Error");
                setAlertMsg(false);
                setAlertError(true);
            }
        }
    }


    return (
        <>
            <div className="d-flex flex-column flex-root login-bg">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <RightBanner />
                    <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 order-2 order-lg-1 right-text">
                        <div class="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                            <div class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                                <div class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">
                                    <Alert className="login-alerts" show={alertError} onClose={() => setAlertError(false)} variant="danger" dismissible>
                                        {errMsg}
                                    </Alert>
                                    <Alert className="login-alerts" show={alertSuccess} onClose={() => setAlertSuccess(false)} variant="success" dismissible>
                                        {successMsg}
                                    </Alert>
                                    {alertMsg ?
                                        <SweetAlert
                                            title=""
                                            timeout={10000}
                                            showConfirm={false}
                                            style={{ width: "200px", height: "100px" }}
                                        > <img src={loading} width="50px" height="50px" />
                                        </SweetAlert>
                                        : <></>}
                                    <form class="form w-100" id="kt_password_reset_form" onSubmit={handleSubmit}>
                                        <div class="text-center mb-10">
                                            <h1 class="text-gray-900 fw-bolder mb-3">
                                                Forgot Password ?
                                            </h1>

                                            <div class="text-gray-500 fw-semibold fs-6">
                                                Enter your email to reset your password.
                                            </div>
                                        </div>

                                        <div class="fv-row mb-8">
                                            <input type="email" value={email} id="email-input"
                                                onChange={(e) => onChangeEmail(e.target.value)} placeholder="Email" name="email" autocomplete="off" class="form-control bg-transparent" />
                                            <span className="input-pre-text"><small>&ensp;</small></span>
                                            {errEmail == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Email.</small></span> : null}
                                        </div>

                                        <div class="d-flex flex-wrap justify-content-center pb-lg-0">
                                            <button type="submit" id="kt_password_reset_submit" class="btn btn-primary me-4">

                                                <span class="indicator-label">
                                                    Submit</span>

                                                <span class="indicator-progress">
                                                    Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                            <Link to="/login" className="btn btn-light">Cancel
                                            </Link>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;

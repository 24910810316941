import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'; // Import Video.js styles
import 'videojs-youtube'; // Import the YouTube plugin

function VideoPlayer(props) {

  // current time of the video in array. The first value represents the minute and the second represents seconds.
  const [currentMinutes, setCurrentMinutes] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  //current time of the video in seconds
  const [currentTimeSec, setCurrentTimeSec] = useState();
  // total duration of the video in the array. The first value represents the minute and the second represents seconds.
  const [duration, setDuration] = useState([0, 0]);
  // current duration of the video in seconds
  const [durationSec, setDurationSec] = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (videoRef.current != null) {
      const { min, sec } = sec2Min(videoRef.current.duration);
      setDurationSec(videoRef.current.duration);
      setDuration([min, sec]);
      const interval = setInterval(() => {
        const { min, sec } = sec2Min(playerRef.current.currentTime());
        console.log("currentTime "+playerRef.current.currentTime());
        setCurrentTimeSec(playerRef.current.currentTime());
        setCurrentMinutes(min);
        setCurrentSeconds(sec);
        props.min(min);
        props.sec(sec);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isPlaying]);

  const sec2Min = (sec) => {
    var min = Math.floor(sec / 60);
    var secRemain = Math.floor(sec % 60);
    if (min < 10) {
      min = '0' + min;
    } else {
      min = '' + min;
    }
    if (secRemain < 10) {
      secRemain = '0' + secRemain;
    } else {
      secRemain = '' + secRemain;
    }
    return {
      min: min,
      sec: secRemain,
    };
  };

  useEffect(() => {
    // Ensure that Video.js player is initialized only once
    if (videoRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: 'auto',
      });

      if (props.videoUrl) {
        playerRef.current.src({ src: props.videoUrl, type: 'video/'+props.videoType });
      }
    }

    // Cleanup function to dispose of the player
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [props.videoUrl]); // Dependency on videoUrl to re-initialize when it changes

  const setVideoTime = (time) => {
    if (playerRef.current) {
      playerRef.current.currentTime(time);
    }
  };


  return (
    <>
    <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
        <video ref={videoRef} className="video-js vjs-default-skin"
          controls={true}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }} />
      </div>
    </>
  );
};

export default VideoPlayer;

import React, { useState, useEffect } from 'react';
import './Signup.css';
import banner from "../../assets/images/banner.png";
import SocialSignup from './SocialSignup';
import { Link } from 'react-router-dom';
import { userSetPassword, userSignup } from '../../services/user';
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import Hide from "../../assets/images/hide.svg";
import Show from "../../assets/images/show.svg";
import loading from "../../assets/images/loader_grey.gif";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { loginUser } from '../../services/login';
import { jwtDecode } from 'jwt-decode';

export default function Signup() {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userId, setUserId] = useState("");
    const [alertMsg, setAlertMsg] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [errEmail, setErrEmail] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [successMsg, setSuccessMsg] = useState();
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [errPassword, setErrPassword] = useState(false);
    const [showPswd, setShowPswd] = useState(false);
    const [jwtToken, setJwtToken] = useState();
    const [username, setUsername] = useState("");

    const showPswdHandler = () => {
        setShowPswd(!showPswd);
    }


    var emailInp, passwordInp;

    useEffect(() => {
        emailInp = document.getElementById("email-input");
        passwordInp = document.getElementById("password-input");
    });



    const validateFields = () => {
        let pass = true;
        if (email.trim() == "") {
            setErrEmail(true);
            emailInp.classList.add("error-input-border");
            pass = false;
        }
        return pass;
    }

    const validatePasswordField = () => {
        let pass = true;

        if (password == "") {
            setErrPassword(true);
            passwordInp.classList.add("error-input-border");
            pass = false;
        }
        return pass;
    }

    const onChangePassword = (val) => {
        if (errPassword == true) {
            passwordInp.classList.remove("error-input-border");
            setErrPassword(false);
        }
        setPassword(val);
    }

    const onChangeEmail = (val) => {
        if (errEmail == true) {
            emailInp.classList.remove("error-input-border");
            setErrEmail(false);
        }
        setEmail(val);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        }
        if (!executeRecaptcha) {
            setErrMsg("reCAPTCHA not ready yet.");
            setAlertError(true);
            return;
        }
        try {
            setAlertMsg(true);
            // Execute reCAPTCHA v3 and get the token
            const recaptchaToken = await executeRecaptcha("signup");
            const response = await userSignup({ email, recaptchaToken });
            if (response.status) {
                setUserId(response.data);
                setAlertMsg(false);
                setAlertError(false);
                setAlertSuccess(true);
                setSuccessMsg("Registered Successfully");
            } else {
                setAlertMsg(false);
                setErrMsg(response.message);
                setAlertError(true);
            }
        } catch (error) {
            console.error("reCAPTCHA execution failed", error);
            setErrMsg("Internal Server Error");
            setAlertMsg(false);
            setAlertError(true);
        }

    }

    const handlePasswordSubmit = async e => {
        e.preventDefault();
        if (!validatePasswordField()) {
            return;
        }
        if (!executeRecaptcha) {
            setErrMsg("reCAPTCHA not ready yet.");
            setAlertError(true);
            return;
        }
        try {
            setAlertMsg(true);
            const recaptchaToken = await executeRecaptcha("signup");
            const response = await userSetPassword({ userId, email, password, recaptchaToken });
            if (response.status) {
                setUsername(email);
                setAlertMsg(false);
                setUserId("");
                setAlertError(false);
                setAlertSuccess(true);
                setSuccessMsg("Password Set Successfully");
                const response = await loginUser({
                    username,
                    password
                });
                if (response.status) {
                    setEmail("");
                    setPassword("");
                    setJwtToken("Y");
                    let tokenInfo = jwtDecode(response.data.token);
                    let tokenObj = JSON.parse(tokenInfo.token);
                    let userStatus = tokenObj['status'];
                    let userId = tokenObj['userId'];
                    let userType = tokenObj['userType'];
                    sessionStorage.setItem('userId', userId);
                    sessionStorage.setItem('userType', userType);
                    sessionStorage.setItem('userStatus', userStatus);
                    sessionStorage.setItem('jwt', response.data.token);
                    window.location.href = '/dashboard';
                }else{
                    setJwtToken("N");
                    window.location.href = '/login';
                  }
                sessionStorage.setItem("flag", 0);
            } else {
                setAlertMsg(false);
                setErrMsg(response.message);
                setAlertError(true);
            }
        } catch (error) {
            console.error("reCAPTCHA execution failed", error);
            setErrMsg("Internal Server Error");
            setAlertMsg(false);
            setAlertError(true);
        }

    }

    return (
        <>

            <div class="white-bg">
                <div class="container">
                    <Link to="https://slidesmap.com" class="logo d-flex align-items-center">
                        <h1 class="header-logo">Slidesmap</h1>
                    </Link>
                </div>
                <div class="d-flex flex-column flex-root" id="kt_app_root">

                    <div class="d-flex flex-column flex-lg-row flex-column-fluid">

                        <div class="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
                            <div class="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">


                                <div class="py-20">

                                    <div class="text-start mb-10">
                                        <h1 class="text-gray-900 mb-3" style={{ fontSize: "50px" }}>
                                            Sign up for Slidesmap
                                        </h1>
                                        <p class="signup-text">Unlock the power of effortless presentation creation and collaboration. Sign up now to get started.</p>

                                    </div>
                                    <Alert className="login-alerts" show={alertError} onClose={() => setAlertError(false)} variant="danger" dismissible>
                                        {errMsg}
                                    </Alert>
                                    {/*<Alert className="login-alerts" show={alertSuccess} onClose={() => setAlertSuccess(false)} variant="success" dismissible>
                                        {successMsg}
                                    </Alert>*/}
                                    {alertMsg ?
                                        <SweetAlert
                                            title=""
                                            timeout={10000}
                                            showConfirm={false}
                                            style={{ width: "200px", height: "100px" }}
                                        > <img src={loading} width="50px" height="50px" />
                                        </SweetAlert>
                                        : <></>}
                                    <div style={{ marginTop: "50px" }}>
                                        {userId ?
                                            <form onSubmit={handlePasswordSubmit}>
                                                <input type="email" value={email} disabled
                                                    class="form-control bg-transparent me-2" />
                                                <div class="fv-row mb-2 mt-8" data-kt-password-meter="true">
                                                    <div class="mb-1">
                                                        <div class="position-relative mb-3">
                                                            <input class="form-control bg-transparent" value={password} id="password-input"
                                                                onChange={(e) => onChangePassword(e.target.value)} type={showPswd ? "text" : "password"} placeholder="Password" name="password" autocomplete="off" />

                                                            <div className="show-hide-pswd-eye" onClick={() => { showPswdHandler() }}>
                                                                {showPswd ? <img src={Hide} /> : <img src={Show} />}
                                                            </div>
                                                            <span className="input-pre-text"><small>&ensp;</small></span>
                                                            {errPassword == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Password.</small></span> : null}
                                                        </div>

                                                    </div>

                                                </div>
                                                <button type="submit" class="btn btn-primary">Submit</button>
                                            </form> :
                                            <form onSubmit={handleSubmit}>
                                                <div class="d-flex">
                                                    <input type="email" value={email} id="email-input"
                                                        onChange={(e) => onChangeEmail(e.target.value)} placeholder="Email" name="email" autocomplete="off" class="form-control bg-transparent me-2" />
                                                    <button type="submit" class="btn btn-primary">Submit</button>
                                                </div>

                                                <div class="mt-3">
                                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                                    {errEmail == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Email.</small></span> : null}
                                                </div>
                                            </form>}
                                    </div>
                                    {userId ? "" : <>
                                        <div class="row mt-8">
                                            <div class="col-md-5 mobile-hide">
                                                <hr />
                                            </div>
                                            <div class="col-md-1 text-center">
                                                <b>or</b>
                                            </div>
                                            <div class="col-md-5 mobile-hide"><hr />
                                            </div></div>

                                        <div class="row g-3 mt-8">
                                            <SocialSignup />


                                        </div>
                                        <div class="text-gray-500 text-center fw-semibold fs-6">
                                            Already have an Account?
                                            <Link to="/login" className="link-primary fw-semibold"> Sign in
                                            </Link>

                                        </div>
                                    </>
                                    }
                                </div>



                            </div>
                        </div>

                        <div class="d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat"
                        >
                            <img class="theme-dark-show mx-auto mw-100 w-100 mb-10 mb-lg-20" src={banner} alt="banner" />
                        </div>
                    </div>
                </div>
                <footer id="footer" class="footer dark-background">

                    <div class="container footer-top">
                        <div class="row gy-4">
                            <div class="col-lg-4 col-md-6 footer-about">
                                <a href="https://slidesmap.com" class="logo d-flex align-items-center">
                                    <span class="sitename">Slidesmap</span>
                                </a>
                                <div class="footer-contact pt-3 text-start">
                                    <p>A108 Adam Street</p>
                                    <p>New York, NY 535022</p>
                                    <p class="mt-3"><strong>Phone:</strong> <span>+1 5589 55488 55</span></p>
                                    <p><strong>Email:</strong> <span>info@example.com</span></p>
                                </div>
                                <div class="social-links d-flex mt-4">
                                    <a href="#"><i class="bi bi-twitter-x"></i></a>
                                    <a href="#"><i class="bi bi-facebook"></i></a>
                                    <a href="#"><i class="bi bi-instagram"></i></a>
                                    <a href="#"><i class="bi bi-linkedin"></i></a>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-3 footer-links">
                                <h4 className="text-start">Useful Links</h4>
                                <ul>
                                    <li><a href="https://slidesmap.com">Home</a></li>
                                    <li><a href="https://slidesmap.com/features.html">Services</a></li>
                                    <li><a href="https://slidesmap.com">Terms of service</a></li>
                                    <li><a href="https://slidesmap.com">Privacy policy</a></li>
                                </ul>
                            </div>


                        </div>
                    </div>

                    <div class="container copyright text-center mt-4">
                        <p>© <span>Copyright</span> <strong class="px-1 sitename">Slidesmap</strong> <span>All Rights Reserved</span></p>
                    </div>

                </footer>
            </div>
        </>
    )
}

//export const API_BASE_URL = "http://localhost:8888/slidesapi/api/v1";
export const API_BASE_URL = "https://slidesmap.com/slidesmapapi/api/v1";
export const LOGIN = API_BASE_URL + "/login";
export const USER_SIGNUP = API_BASE_URL + "/user/register";
export const USER_SET_PASSWORD = API_BASE_URL + "/user/setpassword";
export const FORGOT_PASSWORD = API_BASE_URL + "/user/forgotpassword";
export const UPDATE_PASSWORD = API_BASE_URL + "/user/updatepassword";

export const GET_PRESENTATION_LIST = "/event/list";
export const PRESENTATION_CREATE = "/event/save";
export const UPDATE_PRESENTATION = "/event/update";
export const GET_PRESENTATION_CONFIG = "/event/";
export const GET_SLIDES = "/event/slidedetails/";
export const UPDATE_SLIDES = "/event/slidedetails";
export const UPLOAD_SLIDES = "/event/uploadslides/";
export const GET_VIDEO_DETAILS = "/event/video/";
export const GET_TOPICS = "/event/topics/";
export const UPDATE_TOPICS = "/event/topics";
export const REPLACE_SLIDES = "/event/replaceslides/";
export const DELETE_TOPIC = "/event/topic/delete/";
export const DELETE_PRESENTATION = "/event/delete/";
export const PUBLISH_PRESENTATION = "/event/publish/";

export const GET_PRESENTATION_DETAILS = "/event/details/";

export const GET_LAYOUT_TYPES = "/layouttype/list";

export const CHANGE_PASSWORD = "/user/changepassword";
export const GET_USER_PROFILE = API_BASE_URL + "/user/get";
export const USER_UPDATE_PROFILE = "/user/update";

export const GET_TOKEN = API_BASE_URL + "/getToken";

export const GET_DASHBOARD = API_BASE_URL + "/user/dashboard";

export const RESEND_EMAIL = API_BASE_URL + "/user/resendemail";

//export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'
export const OAUTH2_REDIRECT_URI = 'https://app.slidesmap.com/oauth2/redirect'

export const LOGIN_GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?account_type=login&redirect_uri=' + OAUTH2_REDIRECT_URI;

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?account_type=register&redirect_uri=' + OAUTH2_REDIRECT_URI;
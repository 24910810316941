import React from 'react';
import './Login.css';
import banner from "../../assets/images/banner.png";


function RightBanner() {
    return(
      <>
    <div class="d-flex flex-lg-row-fluid order-1 order-lg-2 left-image">
        <div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100"> 
            <img class="theme-dark-show mx-auto mw-100 w-100 mb-10 mb-lg-20" src={banner} alt="banner" />                 
        </div>
    </div>
    </>
  )
}

export default RightBanner;

import { Link } from "react-router-dom";

function Footer() {
    return (
        <>
            <div id="kt_app_footer" className="app-footer footer">
                <div className="app-container container-xxl py-3 text-center">
                    <div className="text-gray-900 order-2 order-md-1">
                        <Link to="https://slidesmap.com" target="_blank" className="text-gray-800 text-hover-primary">Powered by Slidesmap</Link>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Footer;
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import '../Login/Login.css'

function VerifyEmail() {
    const params = useParams();
    const code = params.code;
    return (
        <>
            <div id="kt_app_toolbar" class="container-xxl">
                <div className="row mt-8">
                    <div className="col-md-12 text-center">
                    <div className="mb-30">
              <p className="logo-text">Slidesmap</p>
            </div>
            <div className="mt-20 mb-30">
                        <h2>Thanks for verifying your email.</h2>
                        <div className="mt-20">
                            <Link to="/login" className="btn btn-primary">
                                Login
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerifyEmail;

import React from 'react';
import { Modal } from 'react-bootstrap';
import '../Login/Login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function VerifyModal(props) {
    const resendEmail = async () => {
        try {
            const response = await resendEmail();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='text-center'>
                <div className="mb-30">
            <FontAwesomeIcon icon={faEnvelope} className="messageIcon" />
            </div>
            <div className="mb-30">
            <h1 className='fs-40'>Verify your email address</h1>
            {props.flag === 0 &&
             <p className='mt-8 fs-18'>We have sent a verification link to email.</p>
            }
             </div>
             {props.flag === 1 && <>
             <p className="fs-18">Seems you have not verified email.</p>
             <p className="fs-18">Please verify to access features</p>
             </>
             }
             {props.flag === 0 && <>
             <p className="fs-18">Click on the link to complete the verification process.</p>
             <p className="fs-18">You might need to <b>check your spam folder.</b></p>
             </>
             }
             <div className="mb-30">
             <div className="mt-20">
             <button className='btn btn-primary' onClick={resendEmail}>Resend email  </button>
             <Link to="/dashboard" className="link-primary fw-semibold ml-20">
             <span className="verify-return">Return to Site</span><FontAwesomeIcon icon={faLongArrowAltRight} />
             </Link>
             </div>
             </div>
                </Modal.Body>
               
            </Modal>
        </>
    )
}
export default VerifyModal;



import { CHANGE_PASSWORD, GET_USER_PROFILE, USER_SIGNUP, USER_UPDATE_PROFILE, FORGOT_PASSWORD, UPDATE_PASSWORD,
  GET_DASHBOARD, 
  USER_SET_PASSWORD,
  RESEND_EMAIL} from "../Constants";
import axiosInterceptor from '../helpers/axiosInterceptor';

export function userSignup(request) {
 return fetch(USER_SIGNUP, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(request)
 })
   .then(data => data.json())
}

export function userSetPassword(request) {
  return fetch(USER_SET_PASSWORD, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(data => data.json())
 }

export function changePassword(newPassword) {
  return axiosInterceptor.post(CHANGE_PASSWORD,
      {
          "newPassword": newPassword
        });
}

export function getUserProfile() {
  return axiosInterceptor.get(GET_USER_PROFILE);
}


export function updateProfile(profileData) {
return axiosInterceptor.post(USER_UPDATE_PROFILE,
  {
    "userId": profileData.userId,
    "firstname": profileData.firstname,
    "lastname": profileData.lastname,
    "mobileno": profileData.mobileno,
    "profileImage": profileData.profileImage
    });
}

export function forgotPassword(request) {
  return fetch(FORGOT_PASSWORD, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(data => data.json())
 }

 export function updatePassword(request) {
  return fetch(UPDATE_PASSWORD, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(data => data.json())
 }

 export function getUserDashboard() {
  return axiosInterceptor.get(GET_DASHBOARD);
}

export function resendEmail() {
  return axiosInterceptor.post(RESEND_EMAIL);
}
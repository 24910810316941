import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import profileIcon from "../../assets/images/profile.png";
import WarningModal from './Presentation/WarningModal';
import { getUserProfile } from '../../services/user';
import { useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation().pathname;
    const [showOptions, setShowOptions] = useState(false);
    const [profileDetails, setProfileDetails] = useState({ profileImage: "" });
    const [showWarning, setShowWarning] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    console.log(location);
    const logout = async () => {
        setShowWarning(false);
        sessionStorage.clear();
        window.location.href = '/login';
    };

    const handleWarningModal = () => {
        setShowWarning(false);
    }

    const signout = () => {
        setShowWarning(true);
    }
    const getProfileDetails = async () => {
        try {
            const response = await getUserProfile();
            setProfileDetails(response.data.data);          
        } catch (error) {
            console.error(error);
        }
    };
    const openMenu = () => {
        setShowMenu(true);
        console.log(true);
      };
     
      const closeMenu = () => {
         setShowMenu(false);
       };

       useEffect(() => {
             getProfileDetails();
    }, []);
    return (
        <>
            <div id="kt_app_header" className="app-header">
            {showMenu ? 
                      <>
                      <div className="side-menu-bg">
        <div className="inner-wrapper">
            <span className="btn-close menu-close-btn" id="btn_sideNavClose" onClick={closeMenu}>
            </span>
            <Link to="/dashboard">
                            <p className="logo-text">Slidesmap</p>
                        </Link>
            <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0 justify-content-lg-center" id="kt_app_header_menu" >
                                                    <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                                        <Link className="menu-link" to="/dashboard" onClick={closeMenu}>
                                                            <span className={location === "/" || location === "/dashboard" ?"menu-title active":"menu-title"}>Dashboard</span>
                                                        </Link>
                                                    </div>
                                                    <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2" >
                                                    <Link className="menu-link" to="/presentation" onClick={closeMenu}>
                                                        <span className={location === "/presentation" ?"menu-title active":"menu-title"}>Presentation</span>
                                                    </Link>
                                                </div>
                                                 
                                       
                                </div>
                    </div>
                    </div>
                      </> : ""}
                <div className="app-container  container-xxl d-flex align-items-stretch justify-content-between " id="kt_app_header_container">
                <div className="app-navbar-item d-lg-none me-n2 d-flex align-items-center" title="Show header menu" onClick={openMenu}>
                            <div className="w-30px h-30px side-menu-btn" id="kt_app_header_menu_toggle">
                            <i class="fas fa-bars" aria-hidden="true"></i>
                            </div>
                        </div>
                   

                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">

                        <div className="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">
                        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
                        <Link to="/dashboard">
                            <p className="logo-text">Slidesmap</p>
                        </Link>
                    </div>
                            <div className="
menu 
menu-rounded  
menu-column 
menu-lg-row 
my-5 
my-lg-0 
align-items-stretch 
fw-semibold
px-2 px-lg-0
" id="kt_app_header_menu" data-kt-menu="true">
                                <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                    <Link className="menu-link" to="/dashboard">
                                        <span className={location === "/" || location === "/dashboard" ?"menu-title active":"menu-title"}>Dashboard</span></Link>
                                </div>
                                <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <Link className="menu-link" to="/presentation">
                                        <span className={location === "/presentation" ?"menu-title active":"menu-title"}>Presentations</span></Link>
                                </div>
                            </div></div>   </div>
                </div>


                <div className="app-navbar flex-shrink-0 pr-10">
                   
                    <div className="app-navbar-item ms-1 ms-md-4" id="kt_header_user_menu_toggle">
                        <div className="cursor-pointer symbol symbol-35px" onMouseOver={() => setShowOptions(true)}>
                        {profileDetails.profileImage ? <img src={profileDetails.profileImage} className="rounded-3" alt="Profile Image" /> : <img src={profileIcon} className="rounded-3" style={{ backgroundColor: "white" }} alt="Profile Image" />}
                        </div>

                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-300px" onMouseOver={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)} style={{ display: showOptions ? "block" : "none", position: "fixed", top: "76px", right: "1%" }}>
                            <div className="menu-item px-3">
                                <div className="menu-content d-flex align-items-center px-3">
                                    <div className="symbol symbol-50px me-5">
                                    {profileDetails.profileImage ? <img alt="Profile Image" src={profileDetails.profileImage}/> : <img src={profileIcon} className="rounded-3" alt="Profile Image" />}
                                    </div>

                                    <div className="d-flex flex-column">
                                        <div className="fw-bold d-flex align-items-center fs-5">
                                        {profileDetails.firstname} {profileDetails.lastname}
                                        </div>

                                        <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">
                                        {profileDetails.email}               </a>
                                    </div>
                                </div>
                            </div>

                            <div className="separator my-2"></div>

                            <div className="menu-item px-5">
                                <Link to="/profile" className="menu-link px-5">
                                    My Profile
                                </Link>
                            </div>

                            <div className="menu-item px-5">
                                <a onClick={signout} className="menu-link px-5">
                                    Sign Out
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {showWarning && <WarningModal show={showWarning} cancel={logout} onHide={handleWarningModal} message={"Do you want to logout?"} />}
        </>
    );
}

export default Header;